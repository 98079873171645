<template>
  <div class="page">
        <div class="page__btns">
          <a-button type="primary" @click="openAdd"> 添加单位 </a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="pagination"
          bordered
          @change="changeTable"
        >
        <span slot="icon" slot-scope="text, record">
          <img :src="record.icon_url" alt="" style="width: 100px; height: 100px; display: inline-block;">
        </span>
          <span slot="action" slot-scope="text, record">
            <div class="action_custom">
              <a-icon type="edit" @click="edit(record)" />
              <a-icon type="delete" @click="deleteHandle(record)" />
            </div>
          </span>
        </a-table>


    <a-modal
      title="单位管理"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item ref="name" label="名称" prop="name">
            <a-input
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="priority" label="优先级">
            <a-input v-model="form.priority" type="number" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  
  
  {
    title: "优先级",
    dataIndex: "priority",
    key: "priority",
    align: 'center'
  },
  
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];

import Cookies from 'js-cookie'

export default {
  name: "GoodsUnits",
  data() {
    return {
      form: {
        name: "",
        icon: "",
        priority: 0,
      },
      loading: false,
      visible: false,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      dataSource: [],
      columns,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      page: 1,
      imageUrl: ''
    };
  },
  created() {
    this.initTable();
  },
  computed: {
    params() {
      return { page: this.page, search: this.searchVal };
    },
    headers () {
      return {'X-CSRFToken': Cookies.get('csrftoken')}
    }
  },
  methods: {
    uploadChange ({file}) {
      console.log(file)
      if (file.status == 'done') {
        this.loading = false;
        this.imageUrl = file.response.image
        this.form.icon = file.response.id
      }
    },
    search() {
      this.page = 1;
      this.initTable();
    },
    changeTable(pagination) {
      console.log(pagination, 'pagination')
      this.page = pagination.current;
      this.initTable();
    },
    openAdd() {
      this.visible = true;
      this.form = {
        name: "",
        icon: "",
        priority: 0,
      };
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.form.id
            ? this.$axios.put(`/units/${this.form.id}/`, this.form)
            : this.$axios.post("/units/", this.form);
          request.then((res) => {
            console.log(res);
            this.visible = false;
            this.$message.success("操作成功");
            this.initTable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    initTable() {
      console.log(this.params, 'params')
      this.$axios.get("/units/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    edit(record) {
      this.visible = true;
      this.$axios.get(`/units/${record.id}/`).then((res) => {
        this.form = res;
      });
    },
    deleteHandle(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/units/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    add(record) {
      console.log(record);
    },
  },
};
</script>
<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>